import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ded5de0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_browse_record = _resolveComponent("browse-record")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_dz_record = _resolveComponent("dz-record")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createVNode(_component_van_tabs, {
      active: _ctx.active,
      "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.active) = $event)),
      "line-width": "20px",
      "line-height": "2px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, { title: "浏览记录" }, {
          default: _withCtx(() => [
            _createVNode(_component_browse_record)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "点赞记录" }, {
          default: _withCtx(() => [
            _createVNode(_component_dz_record)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}