
import { Vue } from "vue-property-decorator";
import api from "@/api/disciplinesafe.ts";
import { parseTime } from "@/utils";

export default class extends Vue {
  refreshing = false;
  loading = false;
  finished = false;
  list: any = [];
  page = {
    pageNum: 1,
    pageSize: 10,
  };
  onLoad() {
    setTimeout(async () => {
      if (this.refreshing) {
        this.page.pageNum = 1;
        this.list = [];
        this.refreshing = false;
      }
      const res: any = await api.getBrowseRecordList({
        id: this.$route.params.id,
        time: parseTime(new Date(), "{y}-{m}-{d} {h}:{i}:{s}"),
        ...this.page,
      });
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.list = [...this.list, ...res.data];
      this.page.pageNum++;
      this.loading = false;
      if (res.data.length <= 40) {
        this.finished = true;
      }
    }, 1000);
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
}
