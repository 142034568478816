
import { Options, Vue } from "vue-property-decorator";
import BrowseRecord from "@/views/app/disciplinesafe/browserecord.vue";
import DzRecord from "@/views/app/disciplinesafe/dzrecord.vue";
@Options({
  components: {
    BrowseRecord,
    DzRecord,
  },
})
export default class extends Vue {
  active = 0;
}
